var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { height: "600px", color: "" } },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { absolute: "", permanent: "", right: "", width: "350" },
          scopedSlots: _vm._u([
            {
              key: "prepend",
              fn: function() {
                return [
                  _c("contact-view", {
                    attrs: {
                      color: _vm.color,
                      label: _vm.$_.get(_vm.widgetData, "label"),
                      type: _vm.$_.get(_vm.widgetData, "type"),
                      contact: _vm.$_.get(_vm.widgetData, "primary_contact")
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("v-divider"),
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  model: {
                    value: _vm.window,
                    callback: function($$v) {
                      _vm.window = $$v
                    },
                    expression: "window"
                  }
                },
                [
                  _vm._l(_vm.items, function(item, index) {
                    return [
                      _c(
                        "v-list-item",
                        { key: index, attrs: { link: "" } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { class: _vm.color + "--text" }, [
                                _vm._v(_vm._s(item.icon))
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(item.title))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      index !== _vm.items.length - 1
                        ? _c("v-divider", { key: "divider-" + index })
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mt-n1 pa-0", attrs: { cols: "7" } },
                [
                  _c(
                    "v-window",
                    {
                      staticClass: "mt-0 pa-0",
                      model: {
                        value: _vm.window,
                        callback: function($$v) {
                          _vm.window = $$v
                        },
                        expression: "window"
                      }
                    },
                    [
                      _vm._l(_vm.items, function(item, index) {
                        return [
                          _c(
                            "v-window-item",
                            { key: index, staticClass: "py-0 pl-2 ma-0" },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex mb-2" },
                                [
                                  _c(
                                    "v-icon",
                                    { class: "mr-2 " + _vm.color + "--text" },
                                    [_vm._v(_vm._s(item.icon))]
                                  ),
                                  _c("h3", { class: _vm.color + "--text" }, [
                                    _vm._v(" " + _vm._s(item.title) + " ")
                                  ])
                                ],
                                1
                              ),
                              _c("v-divider"),
                              _c(
                                item.component,
                                _vm._b(
                                  {
                                    tag: "component",
                                    attrs: { color: _vm.color, type: _vm.type }
                                  },
                                  "component",
                                  _vm.getItemProps(item),
                                  false
                                )
                              )
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }