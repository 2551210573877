<template>
    <v-card height="600px" color="">
        <v-navigation-drawer absolute permanent right width="350">
            <template v-slot:prepend>
                <contact-view
                    :color="color"
                    :label="$_.get(widgetData, 'label')"
                    :type="$_.get(widgetData, 'type')"
                    :contact="$_.get(widgetData, 'primary_contact')"
                ></contact-view>
            </template>
            <v-divider></v-divider>
            <v-list dense>
                <v-list-item-group v-model="window">
                    <template v-for="(item, index) in items">
                        <v-list-item :key="index" link>
                            <v-list-item-icon>
                                <v-icon :class="`${color}--text`">{{
                                    item.icon
                                }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    item.title
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider
                            :key="`divider-${index}`"
                            v-if="index !== items.length - 1"
                        ></v-divider>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-card-text>
            <v-row>
                <v-col cols="7" class="mt-n1 pa-0">
                    <v-window v-model="window" class="mt-0 pa-0">
                        <template v-for="(item, index) in items">
                            <v-window-item class="py-0 pl-2 ma-0" :key="index">
                                <div class="d-flex mb-2">
                                    <v-icon :class="`mr-2 ${color}--text`">{{
                                        item.icon
                                    }}</v-icon>
                                    <h3 :class="`${color}--text`">
                                        {{ item.title }}
                                    </h3>
                                </div>
                                <v-divider></v-divider>
                                <component
                                    :color="color"
                                    :is="item.component"
                                    :type="type"
                                    v-bind="getItemProps(item)"
                                ></component>
                            </v-window-item>
                        </template>
                    </v-window>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "rental-address-widget",
    components: {
        ContactView: () => import("../Contact/ContactView"),
        RentalEmploymentContacts: () =>
            import("../Common/RentalEmploymentContacts"),
        ReferenceWidget: () => import("../Reference/ReferenceWidget"),
        RentalEmploymentNotes: () => import("./RentalEmploymentNotes"),
        RentalEmploymentMessages: () => import("./RentalEmploymentMessages"),
        RentalEmploymentActivity: () => import("./RentalEmploymentActivity"),
    },
    props: {
        widgetData: {
            type: Object,
            required: true,
        },
        menuOptions: {
            type: String,
            required: false,
            default: "screen.rentalAddressMenuOptions",
        },
        type: {
            type: String,
            required: false,
            default: "rental",
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
    },
    data() {
        return {
            window: 0,
            items: this.$config(this.menuOptions),
        };
    },
    methods: {
        getItemProps({ id, title, icon, params }) {
            let props = {};
            for (const key in params) {
                props[key] = this.$_.get(this.widgetData, params[key]);
            }
            return {
                ...props,
                id,
                title,
                icon,
            };
        },
    },
};
</script>

<style scoped>
</style>